import { useState } from "react";
import {
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Autocomplete from '@mui/material/Autocomplete';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { useFavorites } from '../../../contexts/FavoritesContext';
import { useGetData, useEditImage } from '../../../hooks';

export default function Edit ({ data }) {
  const { data: plants } = useGetData('plants');
  const { refetch: refetchFavorites } = useFavorites();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const editImage = useEditImage();

  const onEditClick = () =>
    setIsModalOpen(true);

  const onCancel = () =>
    setIsModalOpen(false);

  const onConfirm = async () => {
    const requestData = {};
    requestData.plantId = showPlantId.value;
    requestData.notes = showNotes.value;
    requestData.id = data.plantPhotoId;
    await editImage(requestData);
    refetchFavorites();
  };

  return (
    <>
      <Box>
        <Tooltip title="Edit metadata">
          <IconButton
            fontSize="small"
            style={imgButtonStyle}
            onClick={onEditClick}>
            <EditIcon />
          </IconButton >
        </Tooltip>
      </Box>
      <Dialog open={isModalOpen}>
        <DialogTitle sx={{fontFamily: 'Lato'}}>Edit</DialogTitle>
        <DialogContent>
          <Autocomplete
              id="showPlantId"
              required
              fullWidth
              sx={{mt:2,mb:5}}
              options={plants}
              renderInput={params => (
                <TextField {...params} label="Product Name" sx={{
                  "& .MuiInputBase-input": { fontFamily: 'Lato' },
                  "& .MuiInputLabel-root": { fontFamily: 'Lato' },
                }} />
              )}
              getOptionLabel={(option) => `${option.id}`}
              getOptionSelected={(option) => option.id === data.plantId}
            />
            <TextField
              sx={{ width: '40ch',
                "& .MuiInputBase-input": { fontFamily: 'Lato' },
                "& .MuiInputLabel-root": { fontFamily: 'Lato' },
               }}
              id="showNotes"
              label="Notes"
              multiline
              rows={3}
              defaultValue={data.notes}
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary" autoFocus sx={{fontFamily: 'Lato'}}>Cancel</Button>
          <Button onClick={onConfirm} color="primary" sx={{fontFamily: 'Lato'}}>Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const imgButtonStyle = {
  backgroundColor: "white",
  margin: "1px"
};
