import axios from "axios";
import { baseUrl } from "../_constants";
import { getAuthToken } from "../utils";

export default function useDeletePlantImage() {
  const deletePlantImage = async (id) => {
    try {
      const api = `${baseUrl}/plant-photos/${id}`;
      const authToken = await getAuthToken();
      if (!authToken) {
        console.error("Not authenticated to delete");
        throw new Error("Unauthenticated");
      }
      return await axios.delete(api, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
    } catch (error) {
      console.error("Error deleting plant image:", error);
      return null; // Or any other appropriate error handling
    }
  };

  return deletePlantImage;
}
