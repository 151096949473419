import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
const SearchInput = ({ placeholder, handleSearch }) => {
  return (
    <Box
      component="form"
      sx={{
        p: "0px 10px",
        display: "flex",
        alignItems: "center",
        width: 600,
        borderRadius: 16,
        border: 1,
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder || "Search for User"}
        inputProps={{ "aria-label": "search for user" }}
        onChange={handleSearch}
      />
      <IconButton type="submit" sx={{ p: "14px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Box>
  );
};
export default SearchInput;
