export default function Heading({ INITIAL_FILTERS, filters, plantPhotos }) {
  if (filters.plantId !== INITIAL_FILTERS.plantId) {
    const isMoreThanOne = plantPhotos?.length > 1;
    return (
      <h6>
        {`Search Results for ${filters.plantId} - ${
          plantPhotos?.length || 0
        } result${isMoreThanOne ? "s" : ""}`}
      </h6>
    );
  }

  return <h6>Most Recent Photos</h6>;
}
