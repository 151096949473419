import MainFeed from './MainFeed';
import Favorites from './Favorites';

export default function Actions({
    page,
    user,
    data,
}) {
    if (page === 'MainFeed') {
        return (
            <MainFeed
                user={user}
                data={data}
            />
        );
    }

    if (page === 'Favorites') {
        return (
            <Favorites
                user={user}
                data={data}
            />
        );
    }

    return null;
}
