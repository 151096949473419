import React from "react";
import { useNavigate } from "react-router-dom";
import Form from "./Form";

export default function PlantImages({ yards, plants }) {
  const navigate = useNavigate();

  const onCreate = () => navigate("/home");

  return (
    <>
      <div className="admin-plants-container">
        <div className="admin-plant-row" data-type="heading">
          <h2>Create New Plant Image</h2>
        </div>
        <Form yards={yards} plants={plants} onCreate={onCreate} />
      </div>
    </>
  );
}
