import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import ImageGrid from "./ImageGrid";
import { getAuthToken } from "../utils";
import { useGetData } from "../hooks";
import CreatePlantPhotoModal from "./PlantImages/Modal";

export default function Home({ user }) {
  document.getElementById("main").classList.add("main");
  let navigate = useNavigate();
  const { data: plantPhotos, refetch: refetchPlantPhotos } = useGetData(
    `plant-photos`,
    false
  );

  const onNewPlantCreate = () => {
    let search = "%2A/%2A";

    refetchPlantPhotos(search);
  };

  useEffect(async () => {
    let authToken = await getAuthToken();

    if (authToken) {
      navigate("/home");
    }
    if (!authToken) {
      navigate("/login");
      return;
    }
  }, []);

  return (
    <>
      <div className="container">
        <Header user={user} />
        <div>
          <div className="home-header-wrapper">
            <h1>WELCOME!</h1>
            <CreatePlantPhotoModal onCreate={onNewPlantCreate} />
          </div>
          <ImageGrid
            plantPhotos={plantPhotos}
            refetchPlantPhotos={refetchPlantPhotos}
            user={user}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}
