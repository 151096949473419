import { useState } from "react";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Button, Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import { useFavorites } from "../../../contexts/FavoritesContext";
import { useDeleteImage } from "../../../hooks";

export default function Delete({ data }) {
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const { refetch: refetchFavorites } = useFavorites();
  const deleteImage = useDeleteImage();

  const onDeleteClick = () => setConfirmModalOpen(true);

  const onCancel = () => setConfirmModalOpen(false);

  const onConfirm = async () => {
    await deleteImage(data.plantPhotoId);
    refetchFavorites();
  };
  return (
    <>
      <Box>
        <Tooltip title="Remove from cart">
          <IconButton
            fontSize="small"
            style={imgButtonStyle}
            onClick={onDeleteClick}
          >
            <RemoveShoppingCartIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Dialog open={isConfirmModalOpen}>
        <DialogTitle>
          Are you sure you want to remove photo from cart?
        </DialogTitle>
        <DialogActions>
          <Button onClick={onCancel} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={onConfirm} color="primary">
            Yes, remove from cart
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const imgButtonStyle = {
  backgroundColor: "white",
  margin: "1px",
};
