import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

import Box from "@mui/material/Box";
import SearchInput from "./SearchInput";
//import Data from "./mock-data.json"
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditDialog from "./EditDialogYardEmail";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { baseUrl } from "../../_constants";
import { getAuthToken } from "../../utils";

import "./style.css";

const DF = new Intl.DateTimeFormat("en-US", { timeZoneName: "short" });

const classes = {
  root: {
    flexGrow: 1,
  },
  id: {
    textAlign: "left",
    backgroundColor: "yellow",
    color: "black",
  },
  paper: {
    paddingRight: "0px",
    paddingLeft: "10px",
    paddingTop: "14px",
    paddingBottom: "14px",
    textAlign: "left",
    backgroundColor: "white",
    color: "black",
    fontFamily:"Lato",
  },
  main: {
    backgroundColor: "green",
  },
  edit: {
    paddingRight: "0px",
    paddingLeft: "10px",
    paddingTop: "14px",
    paddingBottom: "14px",
    backgroundColor: "white",
    fill: "darkgreen",
    cursor: "pointer",
    fontFamily:"Lato",
  },
  editIcon: {
    fill: "blue",
  },
  delete: {
    paddingRight: "0px",
    paddingLeft: "10px",
    paddingTop: "14px",
    paddingBottom: "14px",
    backgroundColor: "white",
    fill: "darkgreen",
    cursor: "pointer",
    fontFamily:"Lato",
  },
  deleteIcon: {
    fill: "red",
  },
  addIcon: {
    fill: "blue",
  },
  header: {
    backgroundColor: "white",
    color: "black",
    fontSize: "20px",
    display: "table",
    cursor: "pointer",
    fontFamily:"Lato",
  },
};
const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "blue",
  border: "1",
}));

const RowItem = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  border: "1",
  color: "blue",
}));

const getAllData = (yardsEmails, handleClickOpenEdit) => {
  if (!yardsEmails)
    return <Box sx={{ flexGrow: 1 }} style={classes.main}></Box>;
  return yardsEmails.map((yardEmailInfo) => {
    return (
      <Box sx={{ flexGrow: 1 }} style={classes.main} key={yardEmailInfo.id}>
        <RowItem container spacing={0} alignItems="center">
          {/*
                <Grid item xs={1}>
                    <Item style={classes.id}>{userInfo.id}</Item>
                </Grid>
                */}
          <Grid item xs={4}>
            <Item style={classes.paper}>{yardEmailInfo.name}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item style={classes.paper}>{yardEmailInfo.email}</Item>
          </Grid>
          <Grid
            item
            xs={1}
            style={classes.edit}
            onClick={() => {
              handleClickOpenEdit(yardEmailInfo);
            }}
          >
            <EditIcon style={classes.editIcon} />
          </Grid>
        </RowItem>
      </Box>
    );
  });
};

const getSomeData = (yardQuery, yardData, handleClickOpenEdit) => {
  const lower = yardQuery.toLowerCase();
  const searchResults = yardData.filter((yardInfo, i) => {
    const yardName = yardInfo.name;
    if (yardName) {
      const nameParts = yardName.split(/[ ]+/);
      return nameParts.some((part) => part.toLowerCase().startsWith(lower));
    } else {
      return false;
    }
  });
  return getAllData(searchResults, handleClickOpenEdit);
};

const useUpdateYardEmail = () => {
  const updateYardEmail = async (id, emailId, name, slug) => {
    const authToken = await getAuthToken();
    if (!authToken) {
      console.error("ImageGrid Not authenticated to updateYardEmail");
      throw new Error("Unauthenticated");
    }

    const api = `${baseUrl}/yards-emails/${id}`;
    return axios.put(
      api,
      { email: emailId, name: name, slug: slug },
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
  };

  return updateYardEmail;
};

const useCreateYardEmail = () => {
  const createYardEmail = async (data) => {
    const authToken = await getAuthToken();
    if (!authToken) {
      console.error("ImageGrid Not authenticated to createYardEmail");
      throw new Error("Unauthenticated");
    }

    const api = `${baseUrl}/yards-emails`;
    return axios.post(api, data, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  return createYardEmail;
};

const getHeaders = () => {
  return (
    <Box sx={{ flexGrow: 1 }} style={classes.main}>
      <RowItem container spacing={0} alignItems="center">
        <Grid item xs={5}>
          <Item style={classes.header}>Yard</Item>
        </Grid>
        <Grid item xs={6}>
          <Item style={classes.header}>Email Address</Item>
        </Grid>
        <Grid item xs={1} style={classes.header}></Grid>
      </RowItem>
    </Box>
  );
};

const getFooter = (handleClickOpenAdd) => {
  return (
    <Box sx={{ flexGrow: 1 }} style={classes.main}>
      <RowItem container spacing={0} alignItems="center">
        <Grid
          item
          align="center"
          xs={1}
          style={classes.edit}
          onClick={() => {
            handleClickOpenAdd({});
          }}
        >
          <AddCircleOutlineIcon style={classes.addIcon} />
        </Grid>
        <Grid item xs={10}>
          <Item
            style={classes.header}
            onClick={() => {
              handleClickOpenAdd({});
            }}
          >
            Add Yard Email
          </Item>
        </Grid>
      </RowItem>
    </Box>
  );
};

/**
 * Admin panel for CRUD operations on users.
 *
 * The loggedInUser is the user who is signed in at the webapp.
 * It initializes the user state variable, and is then passed in to the Header
 * component.
 *
 * See starter code:
 * https://blog.logrocket.com/create-search-bar-react-from-scratch/
 */
export default function YardsEmails({ loggedInUser, yards, roles, usersData }) {
  // Admin panel needs inCard to be set, otherwise Header crashes.
  // Add snackbar when data has been saved #31
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");

  const handleCloseSnackbar = () => {
    setSnackBarOpen(false);
  };

  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [yardEmailInfoToEdit, setYardEmailInfoToEdit] = React.useState({});
  const [yardsEmails, setYardsEmails] = useState("");
  const [yardData, setYardData] = useState(false);
  const [yardQuery, setYardQuery] = useState("");
  console.log("Admin.baseUrl = " + baseUrl);

  document.getElementById("main").classList.add("main");

  let navigate = useNavigate();

  useEffect(async () => {
    const authToken = await getAuthToken();
    if (!authToken) {
      console.log("Going to login, no authToken at all!");
      navigate("/login");
      return;
    }

    if (authToken) {
      const yardEmailProperties = await fetchGetYardEmail();
      setYardsEmails(yardEmailProperties);
    }
  }, [yardData]);

  const handleClickOpenEdit = (yardEmailInfo) => {
    console.log(
      "handleClickOpenEdit editing : " + JSON.stringify(yardEmailInfo)
    );
    setYardEmailInfoToEdit(yardEmailInfo);
    setOpenEdit(true);
  };

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  /**
   * GETs the /users API, which returns information about the signed in user,
   * like yard, role, username.
   *
   * @returns Promise
   */
  const fetchGetYardEmail = async () => {
    const authToken = await getAuthToken();
    if (!authToken) {
      console.error("Admin Not authenticated to fetchGetYardEmail");
      return;
    }
    const url = `${baseUrl}/yards-emails`;
    const result = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const yardEmailProperties = await result.json();
    console.log(
      "Admin.fetchGetYardEmail returns " + JSON.stringify(yardEmailProperties)
    );
    return yardEmailProperties;
  };

  const createSlug = (name) => {
    return name
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const clickedSave = async (yardEmailInfo) => {
    const DEBUG = false;
    const updateYardEmail = useUpdateYardEmail();
    const createYardEmail = useCreateYardEmail();

    try {
      if (yardEmailInfo.id) {
        await updateYardEmail(
          yardEmailInfo.id,
          yardEmailInfo.email,
          yardEmailInfo.name,
          yardEmailInfo.slug
        );
        setSnackBarOpen(true);
        setAlertMessage(
          `Yard ${yardEmailInfo.name} has been updated with ${yardEmailInfo.email}`
        );
        setOpenEdit(false); // close the dialog
        setYardData(true);
      } else {
        await createYardEmail({
          email: yardEmailInfo.email,
          name: yardEmailInfo.name,
          slug: createSlug(yardEmailInfo.name),
        });
        setSnackBarOpen(true);
        setAlertMessage(
          `Yard ${yardEmailInfo.name} has been saved with ${yardEmailInfo.email}`
        );
        setYardData(true);
        setOpenEdit(false); // close the dialog
      }
    } catch (e) {
      console.error(">>> error creating/updating yardEmail", e);
      setYardData(false);

      // Optional: Log error to an external service
      if (DEBUG) {
        console.log(">>> error detail:", e);
      }

      // Display a user-friendly error message
      setSnackBarOpen(true);
      setAlertMessage(
        "An error occurred while saving yard email information. Please try again."
      );

      // Optionally, you can handle specific errors
      if (e.response) {
        // Server responded with a status other than 2xx
        console.error(">>> Server response error:", e.response.data);
      } else if (e.request) {
        // Request was made but no response received
        console.error(">>> Network error or no response:", e.request);
      } else {
        // Something else happened
        console.error(">>> Unexpected error:", e.message);
      }
    }
  };

  const clickedCancel = () => {
    setOpenEdit(false);
    setOpenAdd(false);
    setOpenConfirmDelete(false);
  };

  /**
   * handleSearch is called as a yard types into the "search" input.
   * It is implied that the search is on yard name.
   *
   * @param {*} Yard a string like "Joh" or "Doe"
   */
  const handleSearch = (e) => {
    console.log("Admin.handleSearch search for Yard " + e.target.value);
    setYardQuery(e.target.value || "");
  };

  return (
    <>
      <p></p>
      <SearchInput handleSearch={handleSearch} placeholder="Search for Yard" sx={{fontFamily: 'Lato'}}/>
      <p></p>
      {getHeaders()}
      {"" == yardQuery
        ? getAllData(yardsEmails.data, handleClickOpenEdit)
        : getSomeData(yardQuery, yardsEmails.data, handleClickOpenEdit)}
      {getFooter(handleClickOpenAdd)}
      {/* EditDialog is used both to ADD a user and to EDIT a user */}
      <EditDialog
        title={"Edit Yard Email"}
        addUser={false} // Dialog is used to edit user, not add one
        clickedSave={clickedSave}
        clickedCancel={clickedCancel}
        open={openEdit}
        yardEmailInfo={yardEmailInfoToEdit}
        isDisabled={true}
      />
      <EditDialog
        title={"Add New Yard Email"}
        addUser={true} // Dialog is used to add user
        clickedSave={clickedSave}
        clickedCancel={clickedCancel}
        open={openAdd}
        yards={yardsEmails.data}
        isDisabled={true}
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={alertMessage}
        ContentProps={{ style: { background: "#3ae82a", fontFamily: 'Lato' } }}
      ></Snackbar>
    </>
  );
}
