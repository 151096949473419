import { useState } from "react";
import { Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFavorites } from "../contexts/FavoritesContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import RemoveCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { useGetData } from "../hooks";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import { baseUrl } from "../_constants";
import Alert from "@mui/material/Alert";

import {
  Container,
  Select,
  MenuItem,
  TextField,
  Button,
  Collapse,
  FormControl,
  InputLabel,
} from "@mui/material";

export default function RequestPhoto({ user }) {
  const navigate = useNavigate();
  const { favorites } = useFavorites();
  const [selectedYard, setSelectedYard] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false);
  let [responseMessage, setResponseMessage] = useState("");
  let [alertType, setAlertType] = useState("error");

  const handleYardChange = (event) => {
    setSelectedYard(event.target.value);
  };

  const { data: yards } = useGetData("yards");
  const [emails, setEmails] = useState([]);

  const fetchRequestImages = async (emails, yards) => {
    // Get the current user from local storage
    const currentUser = JSON.parse(localStorage.getItem("current-user"));
    const blob = {};
    blob["emails"] = emails;
    blob["yards"] = yards; // selectedYard;
    blob["favorites"] = favorites;
    blob["user"] = currentUser;

    const response = await fetch(`${baseUrl}/requestphotos`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(blob),
    });

    const data = await response.json();
    return data;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle the submit action here
    if (selectedYard !== "") {
      // Send email to the customer
      //const response = fetchRequestImages(emails, selectedYard || "all");

      fetchRequestImages(emails, selectedYard || "all")
        .then((message) => {
          setResponseMessage(
            "The request was sent successfully,  id:" + message.data
          ); // Assuming message.data contains the response text
          setAlertType("success");
        })
        .catch((error) => {
          setResponseMessage("An error occurred while requesting photos.");
          setAlertType("error");
          console.error("Error: ", error);
        });
    } else {
      alert("Please select a yard.");
    }
  };

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  const goHome = () => {
    navigate("/home");
  };

  const handleEmailChange = (event, newValue) => {
    setEmails(newValue);
  };

  return (
    <Container maxWidth="sm" className="request-photo-form">
      <h3 onClick={toggleFormVisibility}>
        Request Updated Photos
        <span className="open-close">
          {isFormVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </span>
      </h3>
      <Collapse in={isFormVisible}>
        <form onSubmit={handleSubmit}>
          <InputLabel style={{ whiteSpace: "normal", fontFamily: 'Lato' }}>
            A.) Confirm Plants: These are the plants you will be requesting new
            photos from the Yards (Section B) below:
          </InputLabel>

          <div className="selected-plants">
            <ul>
              {favorites.map((plant, index) => (
                <li
                  key={`plant-${plant.plantId}-${index}`}
                  style={{ margin: "5px", listStyleType: "none", fontFamily: 'Lato' }}
                >
                  {index + 1}.) {plant.plantId}
                </li>
              ))}
            </ul>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={11}>
              <InputLabel
                style={{
                  fontSize: "12px",
                  whiteSpace: "normal",
                  marginTop: "0px",
                  fontFamily: 'Lato'
                }}
              >
                If you wish to remove/modify these plants (e.g. choose a
                different container/size), please do so by updating your cart
                contents above by clicking on the “Remove from Cart” button and
                then re-open this module.
              </InputLabel>
            </Grid>
            <Grid item xs={1}>
              <RemoveCartIcon />
            </Grid>
          </Grid>

          <InputLabel style={{ marginTop: "20px",fontFamily: 'Lato' }} id="yard-select-label-step">
            B.) Select Yard
          </InputLabel>
          <FormControl fullWidth margin="normal">
            <InputLabel id="yard-select-label">Select Yard</InputLabel>
            <Select
              labelId="yard-select-label"
              id="yard-select"
              value={selectedYard}
              onChange={handleYardChange}
              label="Select Yard"
              sx={{fontFamily: 'Lato'}}
            >
              {yards?.map((yard, index) => (
                <MenuItem key={`yard-${yard.name}-${index}`} value={yard.name} sx={{fontFamily: 'Lato'}}>
                  {yard.name}
                </MenuItem>
              ))}
              <MenuItem value="all" sx={{fontFamily: 'Lato'}}>All Yards</MenuItem>
            </Select>
          </FormControl>

          <InputLabel style={{ marginTop: "20px", fontFamily: 'Lato' }} id="cc-input-label-step">
            C.) CC Customer with photos upon completion of <strong>each</strong>{" "}
            new plant photo.
          </InputLabel>

          <Autocomplete
            multiple
            freeSolo
            id="email-input"
            options={[]}
            value={emails}
            onChange={handleEmailChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                margin="normal"
                label="CC Customer upon completion"
                placeholder="Add your customer's email addresses here."
              />
            )}
          />

          <InputLabel
            style={{
              marginBottom: "20px",
              fontSize: "12px",
              whiteSpace: "normal",
              fontFamily: 'Lato'
            }}
            id="cc-input-label-step-2"
          >
            Add your customer's email address(es) and press the enter key after each one
            before clicking on the “Submit” button below.
          </InputLabel>

          {responseMessage && (
            <Alert severity={alertType}>{responseMessage}</Alert>
          )}

          <Button
            className="button-submit"
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{fontFamily: 'Lato'}}
          >
            Submit
          </Button>
        </form>
      </Collapse>
    </Container>
  );
}
