// Constants for the domain names
export const REACT_APP_WEB_BASE_DOMAIN_PRODUCTION = "normansnursery.app";
export const REACT_APP_WEB_BASE_DOMAIN_STAGE = "norman-s-nursery-stage.web.app";

// Determine the environment based on the domain
const domain = window.location.hostname;
const environment = domain === REACT_APP_WEB_BASE_DOMAIN_PRODUCTION ? "production" : "stage";

// Set the environment in localStorage
window.localStorage.setItem("environment", environment);

export default {
  stage: {
    REACT_APP_API_BASE_URL: "https://norman-s-nursery-stage.uc.r.appspot.com",
    //REACT_APP_API_BASE_URL: "http://localhost:3000",
    REACT_APP_FIREBASE_API_KEY: "AIzaSyAkAnD9L6NMTAa9ItqrAK_35RmdY2r0Isc",
    REACT_APP_FIREBASE_AUTH_DOMAIN: "norman-s-nursery-stage.firebaseapp.com",
    REACT_APP_FIREBASE_DATABASE_URL:
      "https://norman-s-nursery-stage-default-rtdb.firebaseio.com",
    REACT_APP_FIREBASE_PROJECT_ID: "norman-s-nursery-stage",
    REACT_APP_FIREBASE_STORAGE_BUCKET: "norman-s-nursery-stage.appspot.com",
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID: "1015452895848",
    REACT_APP_FIREBASE_APP_ID: "1:1015452895848:web:dfc548310130c7f0f2c9dd",
    REACT_APP_FIREBASE_MEASUREMENT_ID: "G-FNK9W0RQN8",
    REACT_APP_WEB_BASE_URL: "https://norman-s-nursery-stage.web.app",
  },
  production: {
    REACT_APP_NORMANS_NURSERY_IMAGE_API_BASE_URL:
      "https://image.normansnursery.app",
    REACT_APP_API_BASE_URL: "https://normans-nursery.uc.r.appspot.com",
    REACT_APP_FIREBASE_API_KEY: "AIzaSyBoPiOUKqVg4r8UeBJwnoNw5oAuFaaURA8",
    REACT_APP_FIREBASE_AUTH_DOMAIN: "normans-nursery.firebaseapp.com",
    REACT_APP_FIREBASE_DATABASE_URL:
      "https://normans-nursery-default-rtdb.firebaseio.com",
    REACT_APP_FIREBASE_PROJECT_ID: "normans-nursery",
    REACT_APP_FIREBASE_STORAGE_BUCKET: "normans-nursery.appspot.com",
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID: "958660848828",
    REACT_APP_FIREBASE_APP_ID: "1:958660848828:web:6819f62c0396e9fda534b9",
    REACT_APP_FIREBASE_MEASUREMENT_ID: "G-PJTTJ7M8FN",
    REACT_APP_WEB_BASE_URL: "https://normansnursery.app",
  },
}[environment];
