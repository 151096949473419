import axios from 'axios';
import { baseUrl } from '../_constants';
import { getAuthToken } from '../utils';

export default function useRemoveFromCart() {
  const removeFromCart = async (id) => {
    const authToken = await getAuthToken();
    if (!authToken) {
      console.error('ImageGrid Not authenticated to removeFromCart')
      throw new Error('Unauthenticated')
    }

    const api = `${baseUrl}/user-favorites/${id}`;
    return axios.delete(api, { headers: {"Authorization" : `Bearer ${authToken}`}})
  }

  return removeFromCart;
}