

export function FormatDate(string){
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(string).toLocaleDateString([],options);
}

export function FormatDateToString(date) {
    return date.toISOString()
}

export function DisplayTodaysDate() {
    var newDate = new Date()
    var date = newDate.getDate();
    var shortMonth = newDate.toLocaleString('en-us', { month: 'short' }); /* Jun */
    var year = newDate.getFullYear();
    var show = shortMonth+". "+date+", "+year;
    return show
}

export function getStartAndEndDates (selectedPeriod) {

  var today     = new Date();
  var startDate = "";
  var endDate   = FormatDateToString(today);

  switch (selectedPeriod){
    case "Last 7 Days":
      var sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);
      startDate = FormatDateToString(sevenDaysAgo);
      break;
    case "Last 14 Days":
      var fourteenDaysAgo = new Date(today);
      fourteenDaysAgo.setDate(today.getDate() - 14);
      startDate = FormatDateToString(fourteenDaysAgo);
      break;
    case "Last 30 Days":
      var thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 30);
      startDate = FormatDateToString(thirtyDaysAgo);
      break;
    case "Previous Month":
      startDate = FormatDateToString(new Date(today.getFullYear(), today.getMonth() - 1, 1));
      endDate = FormatDateToString(new Date(today.getFullYear(), today.getMonth(), 0));
      break;
    default:
      startDate = "";
      endDate = "";
      break;
  }

  console.log("Dates",selectedPeriod,startDate,endDate)
  return [startDate,endDate];

}