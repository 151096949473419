import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { InputLabel } from '@mui/material';

export default function YardsDropdown({yards, defaultValue : defaultYard, onChange, error}) {
    const [yard, setYard] = useState("");
    const handleChange = (e) => {
        console.log("handleChange " + JSON.stringify(e));
        console.log("event.target.value " + e.target.value);
        setYard(e.target.value);
        onChange(e); // tell parent
    };
    const value = yard || defaultYard;
    return (
        <FormControl
            required
            error={error}
            variant="outlined" sx={{ m: 1, width: '25ch' }}>
            <InputLabel  sx={{fontFamily: 'Lato'}} >Yard/Location</InputLabel>
            <Select
                fullWidth
                labelId="edit-yard"
                id="edit-yard-select"
                value={value}
                label="Yard/Location"
                onChange={handleChange}
                sx={{fontFamily: 'Lato'}}
            >

            {/*
            <MenuItem value="">
                <em>All Yards</em>
            </MenuItem>
            */}
            {yards ? yards.map((yard, index) => {
                const selected = yard.name == defaultYard;
                // console.log("YardsDropdown yard, index: " + JSON.stringify(yard) + ", " + index + ", selected = " + selected);
                return (
                <MenuItem
                    key={index}
                    value={yard.name}
                    selected={selected}
                    sx={{fontFamily: 'Lato'}}>
                    {yard.name}
                </MenuItem>
            )}) : null
            }
        </Select>
        </FormControl>
    );
};