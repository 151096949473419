import Header from "./Header";
import CartImageGrid from "./CartImageGrid";
import RequestPhoto from "./RequestPhoto"; // Request Photo Form
import { useFavorites } from "../contexts/FavoritesContext";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";

export default function Cart({ user }) {
  const { loading } = useFavorites();
  const location = useLocation();

  document.getElementById("main").classList.add("main");
  if (loading) {
    return (
      <div className="container">
        <Header user={user} inCart="" />
        <div>
          <div className="title">My Photo Cart</div>
          Loading Items
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <Header user={user} />
        <div>
          <div className="title">My Photo Cart</div>
          <CartImageGrid user={user} />
        </div>
        <div>
          <RequestPhoto user={user} />
        </div>
      </div>
      <Footer />
    </>
  );
}
