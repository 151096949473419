import React, { useMemo, useState } from "react";
import { usePaginationWithDots } from "../../../hooks";
import AddPlant from "./AddPlant";
import DeletePlant from "./DeletePlant";
import EditPlant from "./EditPlant";
import SearchInput from "../SearchInput";

const limit = 10;
const visiblePages = 5;

export default function Plants({ plants: initialPlants }) {
  const [plants, setPlants] = useState(initialPlants.data);
  const memoizedPlants = useMemo(() => {
    return plants;
  }, [plants]);

  const totalPages = Math.ceil(memoizedPlants?.length / limit) || 1;
  const { currentPage, renderPagination, goToPage } = usePaginationWithDots(
    totalPages,
    visiblePages
  );

  const onPlantDelete = (id) => {
    setPlants((oldPlants) => oldPlants.filter((plant) => plant.id !== id));
  };

  const onPlantCreate = (id, name) => {
    setPlants((oldPlants) => [
      ...oldPlants,
      {
        id,
        name,
      },
    ]);
  };

  const onPlantUpdate = (id, name) => {
    setPlants((oldPlants) => {
      const newPlants = [...oldPlants];
      const updatedPlantIndex = newPlants.findIndex((plant) => plant.id === id);
      newPlants[updatedPlantIndex].name = name;
      return newPlants;
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value?.toLowerCase();
    if (!value) {
      return setPlants(initialPlants.data);
    }
    setPlants(
      initialPlants.data.filter((plant) => {
        const name = plant.name.toLowerCase();
        const id = plant.id.toLowerCase();
        const text = `${id} - ${name}`;

        return text.search(value) > -1;
      })
    );
    goToPage(1);
  };

  return (
    <>
      <div style={{ margin: "16px 0" }}>
        <SearchInput
          placeholder="Search for a plant"
          handleSearch={handleSearch}
        />
      </div>
      <div className="admin-plants-container">
        <div className="admin-plant-row" data-type="heading">
          <h2>Plant Results</h2>
        </div>
        {memoizedPlants
          ?.slice(limit * (currentPage - 1), limit * currentPage)
          ?.map((plant, index) => (
            <div
              key={`plant-${plant.name}-${index}`}
              className="admin-plant-row"
            >
              <span>
                {plant.id} - {plant.name}
              </span>
              <div className="admin-plant-row-actions">
                <EditPlant
                  name={plant.name}
                  id={plant.id}
                  onUpdate={onPlantUpdate}
                />
                <DeletePlant
                  name={plant.name}
                  id={plant.id}
                  onDelete={onPlantDelete}
                />
              </div>
            </div>
          ))}
        <AddPlant onPlantCreate={onPlantCreate} />
        {renderPagination("admin-plants-")}
      </div>
    </>
  );
}
