import axios from "axios";
import { baseUrl } from "../_constants";
import { getAuthToken } from "../utils";

export default function useCreatePlant() {
  const createPlant = async (data) => {
    const authToken = await getAuthToken();
    if (!authToken) {
      console.error("ImageGrid Not authenticated to createPlant");
      throw new Error("Unauthenticated");
    }

    const api = `${baseUrl}/plants`;
    return axios.post(api, data, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  return createPlant;
}
