import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Input,
  InputLabel,
  Button,
} from "@mui/material";
import useCreatePlant from "../../../hooks/useCreatePlant";

export default function AddPlant({ onPlantCreate }) {
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [plantId, setPlantId] = useState("");
  const createPlant = useCreatePlant();

  const handleOpenCreateDialog = () => {
    setCreateDialogOpen(true);
  };

  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
  };

  const onPlantIdChange = (e) => setPlantId(e.target.value);

  const onSubmit = async () => {
    if (!plantId) {
      return alert("Invalid plant id");
    }
    const [id, name] = plantId.split(" - ");
    if (!id || !name) {
      return alert(
        "Invalid format of plant id, make sure to input with same format as placeholder"
      );
    }
    try {
      await createPlant({ id, name });
      onPlantCreate(id, name);
      setPlantId("");
      handleCloseCreateDialog();
    } catch (e) {
      console.log(">>> error creating plant", e);
    }
  };

  return (
    <>
      <div
        className="admin-plant-row"
        data-type="add-new"
        onClick={handleOpenCreateDialog}
      >
        <img
          src="/icons/plus.svg"
          alt="Add new plant icon"
          width="27.5px"
          height="27.5px"
        />
        <span>Add new plant</span>
      </div>
      <Dialog
        open={isCreateDialogOpen}
        maxWidth="xl"
        onClose={handleCloseCreateDialog}
      >
        <DialogTitle>Add Plant</DialogTitle>
        <DialogContent style={{ width: "450px", paddingTop: 5 }}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel htmlFor="plant-id">Plant ID</InputLabel>
            <Input
              id="plant-id"
              placeholder="e.g. BERBRG-B-01  -  BERBERIS thun. Rose Glow"
              onChange={onPlantIdChange}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="primary" onClick={handleCloseCreateDialog}>
            Cancel
          </Button>
          <button className="primary-btn" onClick={onSubmit}>Add</button>
        </DialogActions>
      </Dialog>
    </>
  );
}
