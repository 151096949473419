import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { InputLabel } from '@mui/material';

// Copy/paste from YardsDropdown. Candidate for refactor.
export default function RolesDropdown({roles, defaultValue : defaultRole, onChange, error}) {
    const [role, setRole] = useState("");
    const handleChange = (e) => {
        setRole(e.target.value);
        onChange(e); // tell parent
    };
    const value = role || defaultRole;
    // console.log("Returns with value set to " + value);
    return (
        <FormControl
        error={error}
        required
        fullWidth
        variant="outlined" sx={{ m: 1, width: '51.5ch' }}
        >
            <InputLabel>Role Permissions</InputLabel>
            <Select
                fullWidth
                labelId="edit-role"
                id="edit-role-select"
                value={value}
                label="Role Permissions"
                onChange={handleChange}
            >

            {/*
            <MenuItem value="">
                <em>All Roles</em>
            </MenuItem>
            */}
            {roles ? roles.map((role, index) => {
                const selected = role.name == defaultRole;
                return (
                <MenuItem
                    key={index}
                    value={role.name}
                    selected={selected}>
                    {role.name}
                </MenuItem>
            )}) : null
            }
        </Select>
        </FormControl>
    );
};