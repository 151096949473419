import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { id } from 'date-fns/locale';

/**
 * The props Object is expected to have:
 * open (a boolean, true or false)
 * clickedSave (a Function to call when user clicks Save)
 * clickedCancel (a Function to call when user clicks Cancel)
 * userInfo (an Object such as {"id":1,"username":"Dylan Rontree",
 * "role":"yard","email":"drontree0@neocho.me","lastSignInTime":"9/1/2021"} )
 * yards (a list of yards, containing name/value pairs such as
 *  [{"name":"Carpinteria"},{"name":"Piru"}...])
 * roles (a list of roles, containing name/value pairs such as
 *  [{"name":"admin","hidden":true,"title":"Admin"},
 *   {"hidden":false,"title":"Tagging Dept.","name":"tagging"},
 *   {"hidden":false,"title":"Yard Dept.","name":"yard"},
 *   {"hidden":false,"title":"Sales Dept.","name":"sales"}])
 * title "Add New User" or "Edit User"
 * addUser (boolean if true, dialog is used to add a user, otherwise used to
 *   edit user)
 *
 * @param {*} props Object
 * @returns EditDialog component
 */
export default function EditDialogYardEmail({open, clickedSave, clickedCancel, yardEmailInfo, title}) {

    const defaultValues = {
        id: yardEmailInfo?.id,
        email: yardEmailInfo?.email,
        name: yardEmailInfo?.name,
        slug: yardEmailInfo?.slug
    };

    const defaultErrors = {};

    const [disabled, setDisabled] = React.useState(false);

    const [values, setValues] = React.useState(defaultValues);

    const [errors, setErrors] = React.useState(defaultErrors);

    const DEBUG = false;

    if (DEBUG) {
        console.log("EditDialog: open : " + open + ", userInfo : " + JSON.stringify(yardEmailInfo) +
        ", values : " +
        JSON.stringify(values));
    }

    /**
     * The input Object is either errors or a copy of errors. As such, it has
     * keys that map to false or true and nothing else. If a single key is
     * true, then true is returned. Otherwise, false is returned.
     *
     * @param {*} obj
     * @returns true if the input Object has a property mapping to true,
     * indicating one of the form variables has an error
     */
    const objectHasErrors = (obj) => {
        let x = Object.keys(obj).some((key) => {
            return obj[key];
        });
        return x;
    };

    const handleChange = (prop) => (event) => {
        if (DEBUG) console.log("handleChange, prop , value , addOkay " + prop + " " +
            event.target.value + " is undefined?? " + (event.target.value == undefined) +
            (event.target.value == "") );
        setValues({ ...values, [prop]: event.target.value });

        let skip = false;
        const hlp = {...errors};

        if (skip) {
            setErrors(hlp);
        } else {
            
            // First check form variables that have special requirements.
            if (prop === "email" || prop === "name") {
                const valid = editValueIsValid(prop, event.target.value);
                // valid is either true or false.
                if (errors[prop] != !valid) {
                    // Example:
                    // errors[email] is true if there is an error. In that case,
                    // !valid is true, and errors[email] should be set to true.
                    hlp[prop] = !valid;
                }
            }
            setErrors(hlp);
        }
        const valueForDisabled = objectHasErrors(hlp);
        setDisabled(valueForDisabled);
    };

    const handleCancel = () => {
        clickedCancel();
        setValues({});
        setErrors(defaultErrors);
        setDisabled(false);
    };

    /**
     * When editing an existing user, this dialog is allowed to have empty
     * fields. This method validates email, username, and password.
     * If a different field is passed in, true is returned.
     * 
     * @param {*} prop
     * @returns {*} true if property is valid (empty or constrained) or false
     * if not valid
     */
    const editValueIsValid = (key, value) => {
        if (value == undefined) return true;
        if (typeof value != "string") return false;
        let result = true;
        switch (key) {
            case "email":
                result = value.endsWith("@neochro.me") ||
                value.endsWith("@normansnursery.com");
                break;
            case "name":
                result = value.length >= 1 && value.length <= 128;
                break;
            default:
                result = true;
        }
        return result;
    }

    const handleSave = () => {
        // TODO FIXME debounce
        if (DEBUG) console.log("EditDialog.handleSave " + JSON.stringify(values));
        const obj = {...values};
        if (yardEmailInfo) {
            obj["id"] = obj["id"] || yardEmailInfo.id;
            obj["name"] = obj["name"] || yardEmailInfo.name;
            obj["email"] = obj["email"] || yardEmailInfo.email;
            obj["slug"] = obj["slug"] || yardEmailInfo.slug;
        }
        clickedSave(obj);
        setValues(defaultValues);
        setErrors(defaultErrors);
        if (DEBUG) console.log("EditDialog.handleSave FINISHED, disabled = " + disabled);
    };

    if (DEBUG) {
        console.log("EditDialog: disabled: " + JSON.stringify(disabled));
        console.log("EditDialog: yardEmailInfo: " + JSON.stringify(yardEmailInfo));
        console.log("EditDialog: name is " + JSON.stringify(yardEmailInfo?.name));
        console.log("EditDialog: errors is " + JSON.stringify(errors));
        console.log("EditDialog: values is " + JSON.stringify(values));
        console.log("EditDialog: defaultValues is " + JSON.stringify(defaultValues));
    }
    return (
    <Dialog open={open} onClose={handleCancel}>
        <DialogTitle sx={{fontFamily: 'Lato'}}>{title}</DialogTitle>
        <DialogContent>
            <TextField
                helperText={"email domain must be normansnursery.com or neochro.me"}
                error={errors.email}
                sx={{ m: 1, width: '51.5ch',
                    "& .MuiInputBase-input": { fontFamily: 'Lato' },
                    "& .MuiInputLabel-root": { fontFamily: 'Lato' },
                    "& .MuiFormHelperText-root": { fontFamily: 'Lato' },
                 }}
                autoFocus
                fullWidth
                required
                id="email"
                label="Email Address"
                type="email"
                variant="outlined"
                margin="normal"
                defaultValue={yardEmailInfo?.email}
                onChange={handleChange("email")}
            />
            <div className="side-by-side">
                <TextField
                    sx={{ m: 1, width: '25ch',
                        "& .MuiInputBase-input": { fontFamily: 'Lato' },
                        "& .MuiInputLabel-root": { fontFamily: 'Lato' },
                        "& .MuiFormHelperText-root": { fontFamily: 'Lato' },
                     }}
                    required
                    helperText={"name is required"}
                    error={errors.name}
                    id="name"
                    label="Name"
                    type="text"
                    variant="outlined"
                    margin="normal"
                    defaultValue={yardEmailInfo?.name}
                    onChange={handleChange("name")}
                />
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCancel} sx={{fontFamily: 'Lato'}}>Cancel</Button>
            <Button disabled={disabled} onClick={() => {
                setDisabled(true);
                handleSave();
            }} sx={{fontFamily: 'Lato'}}>Save</Button>
        </DialogActions>
    </Dialog>
    );
}