import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import YardsDropdown from './YardsDropdown';
import RolesDropdown from './RolesDropdown';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

/**
 * The props Object is expected to have:
 * open (a boolean, true or false)
 * clickedSave (a Function to call when user clicks Save)
 * clickedCancel (a Function to call when user clicks Cancel)
 * userInfo (an Object such as {"id":1,"username":"Dylan Rontree",
 * "role":"yard","email":"drontree0@neocho.me","lastSignInTime":"9/1/2021"} )
 * yards (a list of yards, containing name/value pairs such as
 *  [{"name":"Carpinteria"},{"name":"Piru"}...])
 * roles (a list of roles, containing name/value pairs such as
 *  [{"name":"admin","hidden":true,"title":"Admin"},
 *   {"hidden":false,"title":"Tagging Dept.","name":"tagging"},
 *   {"hidden":false,"title":"Yard Dept.","name":"yard"},
 *   {"hidden":false,"title":"Sales Dept.","name":"sales"}])
 * title "Add New User" or "Edit User"
 * addUser (boolean if true, dialog is used to add a user, otherwise used to
 *   edit user)
 *
 * @param {*} props Object
 * @returns EditDialog component
 */
export default function EditDialog({open, clickedSave, clickedCancel, userInfo, yards, roles, title, addUser, isDisabled}) {

    const defaultValues = {
        email: userInfo?.email,
        username: userInfo?.username,
        password: "",
        confirmPassword: "",
        role: userInfo?.role,
        yard: userInfo?.yard,
        showPassword: false
    };

    const defaultErrors = {
        email: addUser,
        username: addUser,
        password: addUser,
        confirmPassword: addUser,
        role: addUser,
        yard: addUser
    };

    const [disabled, setDisabled] = React.useState(isDisabled);

    const [values, setValues] = React.useState({});

    const [errors, setErrors] = React.useState(defaultErrors);

    const DEBUG = false;

    if (DEBUG) {
        console.log("EditDialog: open : " + open + ", userInfo : " + JSON.stringify(userInfo) +
        ", yards : " + JSON.stringify(yards) + ", roles : " + JSON.stringify(roles) +
        ", title : " + title + ", values : " +
        JSON.stringify(values));
    }

    /**
     * The input Object is either errors or a copy of errors. As such, it has
     * keys that map to false or true and nothing else. If a single key is
     * true, then true is returned. Otherwise, false is returned.
     *
     * @param {*} obj
     * @returns true if the input Object has a property mapping to true,
     * indicating one of the form variables has an error
     */
    const objectHasErrors = (obj) => {
        let x = Object.keys(obj).some((key) => {
            return obj[key];
        });
        return x;
    };

    const handleChange = (prop) => (event) => {
        if (DEBUG) console.log("handleChange, prop , value , addOkay " + prop + " " +
            event.target.value + " is undefined?? " + (event.target.value == undefined) +
            (event.target.value == "") );
        setValues({ ...values, [prop]: event.target.value });

        let skip = false;
        const hlp = {...errors};
        if (addUser) {
            if (event.target.value == undefined ||
            event.target.value.trim() == "") {
                // When adding a user, you cannot set any empty fields.
                hlp[prop] = true;
                skip = true;
            } else if (prop === "yard" || prop === "role") {
                // yard and role can take any value set on them from dropdown,
                // skip further validation.
                hlp[prop] = false;
                skip = true;
            }
        }

        if (skip) {
            setErrors(hlp);
        } else {
            // First check form variables that have special requirements.
            if (prop === "email" || prop === "username" || prop === "password") {
                const valid = editValueIsValid(prop, event.target.value);
                // valid is either true or false.
                if (errors[prop] != !valid) {
                    // Example:
                    // errors[email] is true if there is an error. In that case,
                    // !valid is true, and errors[email] should be set to true.
                    hlp[prop] = !valid;
                }
            }

            if (prop === "confirmPassword") {
                hlp[prop] = values.password != event.target.value;
            } else if (prop === "password") {
                hlp["confirmPassword"] = values.confirmPassword != event.target.value;
            }
            setErrors(hlp);
        }
        const valueForDisabled = objectHasErrors(hlp);
        setDisabled(valueForDisabled);
    };
    
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleCancel = () => {
        clickedCancel();
        setValues({});
        setErrors(defaultErrors);
        setDisabled(false);
    };

    /**
     * When editing an existing user, this dialog is allowed to have empty
     * fields. This method validates email, username, and password.
     * If a different field is passed in, true is returned.
     * 
     * @param {*} prop
     * @returns {*} true if property is valid (empty or constrained) or false
     * if not valid
     */
    const editValueIsValid = (key, value) => {
        if (value == undefined) return true;
        if (typeof value != "string") return false;
        let result = true;
        switch (key) {
            case "email":
                result = value.endsWith("@neochro.me") ||
                value.endsWith("@normansnursery.com");
                break;
            case "username":
                result = value.length >= 1 && value.length <= 128;
                break;
            case "password":
                result = value.length >= 6 && value.length <= 128;
                break;
            default:
                result = true;
        }
        return result;
    }

    const handleSave = () => {
        // TODO FIXME debounce
        if (DEBUG) console.log("EditDialog.handleSave " + JSON.stringify(values));
        const obj = {...values};
        delete obj["showPassword"];
        if (userInfo && userInfo.id) {
            obj["id"] = userInfo.id;
        }
        clickedSave(obj);
        //setValues(defaultValues);
        //setErrors(defaultErrors);
        if (DEBUG) console.log("EditDialog.handleSave FINISHED, disabled = " + disabled);
    };

    if (DEBUG) {
        console.log("EditDialog: disabled: " + JSON.stringify(disabled));
        console.log("EditDialog: userInfo: " + JSON.stringify(userInfo));
        console.log("EditDialog: title is " + JSON.stringify(title));
        console.log("EditDialog: username is " + JSON.stringify(userInfo?.username));
        console.log("EditDialog: errors is " + JSON.stringify(errors));
        console.log("EditDialog: values is " + JSON.stringify(values));
        console.log("EditDialog: defaultValues is " + JSON.stringify(defaultValues));
    }
    return (
    <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <TextField
                helperText={"email domain must be normansnursery.com or neochro.me"}
                error={errors.email}
                sx={{ m: 1, width: '51.5ch' }}
                autoFocus
                fullWidth
                required
                id="email"
                label="Email Address"
                type="email"
                variant="outlined"
                margin="normal"
                defaultValue={userInfo?.email}
                onChange={handleChange("email")}
            />
            <div className="side-by-side">
                <TextField
                    sx={{ m: 1, width: '25ch' }}
                    required
                    helperText={"user name is required"}
                    error={errors.username}
                    id="username"
                    label="User Name"
                    type="text"
                    variant="outlined"
                    margin="normal"
                    defaultValue={userInfo?.username}
                    onChange={handleChange("username")}
                />
                <YardsDropdown
                    required
                    error={errors.yard}
                    yards={yards}
                    defaultValue={userInfo?.yard || ""}
                    onChange={handleChange("yard")}
                    helperText={"yard is required"}
                />
            </div>
            <div className="side-by-side">
                <TextField
                    error={errors.password}
                    color="success"
                    id="password"
                    label="Password"
                    variant="outlined"
                    helperText={"must be 6 or more characters"}
                    type={values.showPassword ? "text" : "password"}
                    required={addUser}
                    sx={{ m: 1, width: '25ch' }}
                    onChange={handleChange('password')}
                    InputProps={{ 
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                    }}
                />
                <TextField
                    error={errors.confirmPassword}
                    color="success"
                    id="confirmPassword"
                    label="Confirm Password"
                    variant="outlined"
                    helperText={"must match password"}
                    type={values.showPassword ? "text" : "password"}
                    required={addUser}
                    sx={{ m: 1, width: '25ch' }}
                    onChange={handleChange("confirmPassword")}
                    InputProps={{ 
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle confirm password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        )
                    }}
                />
            </div>
            <RolesDropdown
                roles={roles}
                error={errors.role}
                helperText="role is required"
                defaultValue={userInfo?.role || ""}
                onChange={handleChange("role")}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button disabled={disabled} onClick={() => {
                setDisabled(true);
                handleSave();
            }}>Save</Button>
        </DialogActions>
    </Dialog>
    );
}