import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import useDeletePlant from "../../../hooks/useDeletePlant";

export default function DeletePlant({ name, id, onDelete }) {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const deletePlantId = useDeletePlant();

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const onSubmit = async () => {
    try {
      await deletePlantId(id);
      handleCloseDeleteDialog();
      onDelete(id);
    } catch (e) {
      console.log(">>> error deleting plant", e);
    }
  };

  return (
    <>
      <img
        src="/icons/trash.svg"
        alt="Delete plant row"
        width="26px"
        height="29px"
        onClick={handleOpenDeleteDialog}
      />
      <Dialog
        open={isDeleteDialogOpen}
        maxWidth="xl"
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle>
          Are you sure you want to do Delete this Plant?
        </DialogTitle>
        <DialogContent style={{ width: "450px", paddingTop: 5 }}>
          <span className="">Plant ID</span>
          <br />
          {id} - {name}
        </DialogContent>
        <DialogActions>
          <Button variant="primary" onClick={handleCloseDeleteDialog}>
            Cancel
          </Button>
          <button className="primary-btn" onClick={onSubmit}>Yes, Delete it</button>
        </DialogActions>
      </Dialog>
    </>
  );
}
