import {
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import Archive from './Archive';
import Edit from './Edit';
import Delete from './Delete';
import { openFileInNewWindow } from '../../../helpers';

export default function FavoritesActions({ user, data }) {
return (
  <Box>
    <Box>
      <Tooltip title="Open image in a new window">
        <IconButton
          fontSize="small"
          style={imgButtonStyle}
          onClick={() => openFileInNewWindow(data.downloadUrl)}>
          <LaunchIcon />
        </IconButton>
      </Tooltip>
    </Box>
    {/* <Box>
      <Tooltip title="Archive image">
        <IconButton
          fontSize="small"
          style={imgButtonStyle}
          onClick={() => confirmArchive(tile.plantPhotoId)} >
          <ArchiveIcon />
        </IconButton>
      </Tooltip>
    </Box> */}
    <Archive data={data} />
    <Edit data={data} />
    <Delete data={data} />
  </Box>
)
}

const imgButtonStyle = {
backgroundColor: "white",
margin: "1px"
};