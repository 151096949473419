import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

// See https://mui.com/components/dialogs/#transitions
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteConfirmDialog({userInfo, open, clickedDelete, clickedCancel}) {

    const handleClose = () => {
        clickedCancel();
    };

    const handleClickedDelete = () => {
        clickedDelete(userInfo);
    }

    return (
        <div>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Delete User?"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                {`Are you sure you want to delete user \"${userInfo.username}\"?`}
                {` `}{`It cannot be undone.`}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={clickedCancel}>Cancel</Button>
            <Button onClick={handleClickedDelete}>Yes, Delete</Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}
