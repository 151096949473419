import React, {
    useState,
    useEffect,
    createContext,
    useContext,
} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { baseUrl } from '../_constants';
import { getAuthToken } from '../utils';

const FavoritesContext = createContext({ favorites: [], isLoading: false, isFavorite: false, refetch: () => {} });

export const useFavorites = () => (
    useContext(FavoritesContext)
)

export const FavoritesProvider = ({ children, user }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [favorites, setFavorites] = useState([]);

    const toggleLoading = () => setLoading(loading => !loading);

    const getCartItems = async () => {
        if(user && user?.data?.role && (user?.data.role == 'admin' || user?.data.role == 'tagging')) {
            try {
                toggleLoading()
                const authToken = await getAuthToken()
                if (!authToken) {
                    navigate('/login')
                    return
                }

                
                const response = await axios.get(`${baseUrl}/user-favorites/`, { headers: {"Authorization" : `Bearer ${authToken}`}})

                const mapFavorite = (favorite => ({
                    ...favorite,
                    downloadUrl: favorite.labeledUrl,
                }))

                setFavorites(response.data.data.map(mapFavorite));
            } catch (e) {
                console.log('>>> ERROR GETTING FAVORITES', e);
            } finally {
                toggleLoading()
            }
        }
    }

    const isFavorite = (url) =>
        !!favorites.find(favorite => favorite.labeledUrl === url);

    useEffect(() => {
        getCartItems();
    }, [user]);

    return (
        <FavoritesContext.Provider value={{
            favorites,
            loading,
            isFavorite,
            refetch: getCartItems,
        }}>
            {children}
        </FavoritesContext.Provider>
    )
}
