import {
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';
import Actions from './Actions';

export default function ImageCard({
  page = 'MainFeed',
  user,
  data,
  id,
}) {
  return (
    <ImageListItem id={id}>
      <img
        src={`${data.downloadUrl}?w=248&fit=crop&auto=format`}
        srcSet={`${data.downloadUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
        alt={data.plantId}
        loading="lazy"
      />
      <ImageListItemBar
        position="top"
        sx={{
          background:
            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 100%)',
        }}
        actionIcon={<Actions page={page} user={user} data={data} />}
      />
    </ImageListItem>
  )
}
