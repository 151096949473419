import { useState } from 'react';
import ArchiveIcon from '@mui/icons-material/Inventory';
import {
    Box,
    IconButton,
    Tooltip,
} from '@mui/material';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
} from '@material-ui/core';
import { useFavorites } from '../../../contexts/FavoritesContext';
import { useArchiveImage } from '../../../hooks';

export default function Archive({ data }) {
    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
    const { refetch: refetchFavorites } = useFavorites();
    const archiveImage = useArchiveImage();

    const onArchiveClick = () =>
        setConfirmModalOpen(true);

    const onCancel = () =>
        setConfirmModalOpen(false);

    const onConfirm = async () => {
        await archiveImage(data.plantPhotoId);
        refetchFavorites();
    }
    return (
        <>
            <Box>
                <Tooltip title="Archive Image">
                    <IconButton
                        fontSize="small"
                        style={imgButtonStyle}
                        onClick={onArchiveClick}>
                        <ArchiveIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Dialog open={isConfirmModalOpen}>
                <DialogTitle>Are you sure you want to archive this image?</DialogTitle>
                <DialogActions>
                    <Button onClick={onCancel} color="primary" autoFocus>Cancel</Button>
                    <Button onClick={onConfirm} color="primary">Yes, archive it</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const imgButtonStyle = {
    backgroundColor: "white",
    margin: "1px"
};
