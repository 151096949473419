import axios from "axios";
import { baseUrl } from "../_constants";
import { getAuthToken } from "../utils";

export default function useCreatePlantImage() {
  const createPlantImage = async (form) => {
    const authToken = await getAuthToken();
    if (!authToken) {
      console.error("ImageGrid Not authenticated to createPlantImage");
      throw new Error("Unauthenticated");
    }

    const api = `${baseUrl}/plant-photos`;
    return axios.post(api, form, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
  };

  return createPlantImage;
}
