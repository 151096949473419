import {
    Box,
    IconButton,
  } from '@mui/material';
  import LaunchIcon from '@mui/icons-material/Launch';
  import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
  import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
  import DeleteIcon from '@mui/icons-material/Delete'; 
  import { green } from '@mui/material/colors';
  import { useFavorites } from '../../../contexts/FavoritesContext';
  import { useAddToCart, useRemoveFromCart } from '../../../hooks';
  import { openFileInNewWindow, getUserPermissions } from '../../../helpers';
  import useDeletePlantImage from '../../../hooks/useDeletePlantImage';
  
  export default function MainFeedActions({ user, data }) {
    const addToCart = useAddToCart();
    const removeFromCart = useRemoveFromCart();
    const deletePlantImage = useDeletePlantImage();
    const { isFavorite, refetch: refetchFavorites } = useFavorites();
    const { viewCartPermission } = getUserPermissions(user);
  
    const onAddToCart = async () => {
      await addToCart(data.id);
      refetchFavorites();
    }
  
    const onRemoveFromCart = async () => {
      await removeFromCart(data.id);
      refetchFavorites();
    }

    const onDelete = async () => {
      // Show confirmation alert
      const isConfirmed = window.confirm("Are you sure to delete this image? ");
      if (isConfirmed) {
        try {
          const response = await deletePlantImage(data.id);
          if (response && response.status === 200) {
            document.getElementById('image-photo-'+ data.id).remove(); // we remove the element
            // Display success message
            alert("Image successfully deleted.");
          } else {
            // Handle the case where the response is not successful
            alert("Failed to delete the image.");
          }
        } catch (error) {
          // Handle errors here, such as displaying an error message
          alert("An error occurred while deleting the image.");
        }
      }
    };
 
  
    return (
      <Box>
        <Box>
          <IconButton
            fontSize="small"
            style={imgButtonStyle}
            onClick={() => openFileInNewWindow(data.downloadUrl)}>
              <LaunchIcon />
          </IconButton>
        </Box>
        {viewCartPermission && !isFavorite(data.downloadUrl) && (
          <Box>
            <IconButton
              fontSize="small"
              style={imgButtonStyle}
              onClick={onAddToCart}>
                <AddShoppingCartIcon />
              </IconButton>
          </Box>
        )}
        {viewCartPermission && isFavorite(data.downloadUrl) && (
          <Box>
            <IconButton
              fontSize="small"
              style={imgButtonStyle2}
              onClick={onRemoveFromCart}>
                <ShoppingCartIcon sx={{ color: green }} />
            </IconButton>
          </Box>
        )}
        {/* Delete Button, visible only for admins */}
      {user?.data?.role === "admin" && (
        <Box>
          <IconButton
            fontSize="small"
            style={{ ...imgButtonStyle }}
            onClick={onDelete}>
              <DeleteIcon />
          </IconButton>
        </Box>
      )}
      </Box>
    )
  }
  
  const imgButtonStyle = {
    backgroundColor: "white",
    margin: "1px"
  };
  
  const imgButtonStyle2 = {
    backgroundColor: "green",
    margin: "1px",
  };