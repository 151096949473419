import axios from "axios";
import { baseUrl } from "../_constants";
import { getAuthToken } from "../utils";

export default function useDeletePlant() {
  const deletePlant = async (id) => {
    const authToken = await getAuthToken();
    if (!authToken) {
      console.error("ImageGrid Not authenticated to deletePlant");
      throw new Error("Unauthenticated");
    }

    const api = `${baseUrl}/plants/${id}`;
    return axios.delete(api, "", {
      headers: { Authorization: `Bearer ${authToken}` },
    });
  };

  return deletePlant;
}
