import axios from "axios";
import { baseUrl } from "../_constants";
import { getAuthToken } from "../utils";

export default function useUpdatePlant() {
  const updatePlant = async (id, name) => {
    const authToken = await getAuthToken();
    if (!authToken) {
      console.error("ImageGrid Not authenticated to updatePlant");
      throw new Error("Unauthenticated");
    }

    const api = `${baseUrl}/plants/${id}`;
    return axios.put(
      api,
      { name },
      {
        headers: { Authorization: `Bearer ${authToken}` },
      }
    );
  };

  return updatePlant;
}
