import React, { useState } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { sendPasswordResetEmail } from "firebase/auth";

import { auth } from '../firebase-config';

export default function ForgotPassword() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const onSubmit = async () => {
        if (email) {
            await sendPasswordResetEmail(auth, email, { url: `${window.location.origin}/login` })
            alert('Password reset email sent!');
            navigate('/login');
        } else {
            alert('Email field is required!');
        }
    }

    return (
        <div className="login">
            <div className="container-header">
                <div className="logo"></div>
                <div className="login-title">Password Reset</div>
            </div>

            <Box
                component="form"
                sx={{
                    m: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                noValidate
                autoComplete="off"

                >
                <TextField
                    color="success"
                    id="email"
                    label="Email Address"
                    required
                    variant="outlined"
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{width: '400px'}} 
                />
                <Button
                  variant="contained"
                  sx={{ mt: 2, mb: 2, width: '400px', bgcolor: 'green' }}
                  onClick={onSubmit}
                >
                  Reset Password
                </Button>
                <ReactLink to="/login">
                    <Link href="#" variant="body2">
                        Login
                    </Link>
                </ReactLink>
            </Box>

        </div>
    )
}
