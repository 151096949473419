import React, { useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useForm } from "react-hook-form";
import {
  Autocomplete,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";
import useCreatePlantImage from "../../hooks/useCreatePlantImage";

export default function Form({ yards, plants, onCreate }) {
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    status: null,
    message: null,
  });
  const createPlantImage = useCreatePlantImage();
  const { register, handleSubmit, setValue, getValues, reset, watch } = useForm(
    {
      defaultValues: {
        plantName: "",
        plant: "",
        notes: "",
        yard: "",
        size: "",
        image: null,
      },
    }
  );

  const suggestions = useMemo(() => {
    return plants?.data?.map((row) => ({
      ...row,
      label: row.id,
    }));
  }, [plants]);

  const onSnackBarClose = () =>
    setSnackbar({ isOpen: false, status: null, message: null });

  const onPlantChange = (e, value) => {
    setValue("plant", value.id);
    setValue("size", value.id.split("-")[2]);
    setValue("plantName", value.name);
  };

  const onImageChange = (e) => {
    const file = e.target.files[0];
    setImagePreview(URL.createObjectURL(file));
    setValue("image", file);
  };

  const onSubmit = (values) => {
    let form = new FormData();
    form.append("yard", values.yard);
    form.append("plantId", values.plant);
    form.append("plantName", values.plantName);
    form.append("plantPhoto", values.image);

    // Remove notes in post request if not needed
    if (values.notes && values.notes.trim() !== "") {
      form.append("notes", values.notes);
    }
    setIsLoading(true);
    createPlantImage(form)
      .then((response) => {
        reset();
        onCreate();
      })
      .catch((e) => {
        const message = 'Error: '+ e.response?.data?.message || e.message;
        setSnackbar({
          isOpen: true,
          status: "error",
          message: message,
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack mx={3} spacing={3}>
          <input
            type="file"
            id="image"
            style={{ display: "none" }}
            accept="image/png, image/jpeg"
            onChange={onImageChange}
          />
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              id="plant"
              options={suggestions}
              sx={{ width: 400, div: { borderRadius: "8px" }, fontFamily: 'Lato' }}
              renderInput={(params) => (
                <TextField {...params} label="Search plant" sx={{ fontFamily: 'Lato' }} />
              )}
              {...register("plant")}
              onChange={onPlantChange}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="yard">Yard</InputLabel>
            <Select
              labelId="yard"
              id="yard"
              label="Yard"
              sx={{
                width: 400,
                fontFamily: 'Lato',
                "& .MuiInputBase-input": { fontFamily: 'Lato' },
                "& .MuiInputLabel-root": { fontFamily: 'Lato' },
                "& .MuiList-root": { fontFamily: 'Lato' },
              }}
              {...register("yard")}
            >
              {yards?.data?.map((yard) => (
                <MenuItem key={yard.name} value={yard.name} sx={{ fontFamily: 'Lato' }}>
                  {yard.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <InputLabel
            htmlFor="image"
            style={{
              fontWeight: 600,
              cursor: "pointer",
              width: 204,
              height: 147,
              borderRadius: "8px",
              border: "1px solid #DADBDD",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              fontFamily: 'Lato',
              ...(imagePreview
                ? {
                    backgroundImage: `url(${imagePreview})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }
                : null),
            }}
          >
            <AddIcon sx={{ mb: 1 }} />
            {watch("image") ? `Click to change image` : "Click to upload image"}
          </InputLabel>
        </Stack>
        <FormControl fullWidth>
          <TextField
            placeholder="Notes"
            multiline
            minRows={3}
            maxRows={4}
            variant="filled"
            sx={{
              mt: 3,
              pt: 0,
              "*": { borderRadius: "0" },
              "& .MuiInputBase-input": { fontFamily: 'Lato' },
              "& .MuiInputLabel-root": { fontFamily: 'Lato' },
            }}
            {...register("notes")}
          />
        </FormControl>
        <Button
          size="large"
          type="submit"
          disabled={isLoading}
          sx={{
            backgroundColor: "#066F50",
            color: "white",
            margin: "0 !important",
            width: "100%",
            fontFamily: 'Lato',
          }}
        >
          {isLoading ? "Loading ..." : "Save"}
        </Button>
      </form>
      <Snackbar
        open={snackbar.isOpen}
        autoHideDuration={6000}
        onClose={onSnackBarClose}
      >
        <Alert
          onClose={onSnackBarClose}
          severity={snackbar.status}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
