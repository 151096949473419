import React, { useState } from "react";

const usePaginationWithDots = (totalPages, visiblePages = 5) => {
  const [currentPage, setCurrentPage] = useState(1);

  const getPageRange = () => {
    let startPage, endPage;

    if (totalPages <= visiblePages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const maxPagesBeforeCurrent = Math.floor(visiblePages / 2);
      const maxPagesAfterCurrent = Math.ceil(visiblePages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrent) {
        startPage = 1;
        endPage = visiblePages;
      } else if (currentPage + maxPagesAfterCurrent >= totalPages) {
        startPage = totalPages - visiblePages + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - maxPagesBeforeCurrent;
        endPage = currentPage + maxPagesAfterCurrent;
      }
    }

    return [
      1,
      ...(startPage > 2 ? [null] : []),
      ...Array(endPage - startPage + 1),
    ].map((_, i) => startPage + i);
  };

  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const goToPreviousPage = () => {
    goToPage(currentPage - 1);
  };

  const goToNextPage = () => {
    goToPage(currentPage + 1);
  };

  const renderPagination = (classNamePrefix) => {
    const pages = getPageRange();

    return (
      <div className={`${classNamePrefix}pagination admin-plant-row`}>
        <button onClick={goToPreviousPage} disabled={currentPage === 1}>
          Previous
        </button>
        {pages
          .filter((page) => page <= totalPages)
          .map((page, index) => (
            <React.Fragment key={`pagination-item-${index}-${currentPage}`}>
              {page === null ? (
                <span className={`${classNamePrefix}pagination-dot`}>...</span>
              ) : (
                <button
                  onClick={() => goToPage(page)}
                  className={
                    currentPage === page
                      ? `${classNamePrefix}pagination-active`
                      : ""
                  }
                >
                  {page}
                </button>
              )}
            </React.Fragment>
          ))}
        {currentPage + Math.ceil(visiblePages / 2) < totalPages && (
          <>
            <span className={`${classNamePrefix}pagination-dot`}>...</span>
            <button onClick={() => goToPage(totalPages)}>{totalPages}</button>
          </>
        )}
        <button onClick={goToNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    );
  };

  return {
    getPageRange,
    currentPage,
    goToNextPage,
    goToPreviousPage,
    goToPage,
    renderPagination,
  };
};

export default usePaginationWithDots;
