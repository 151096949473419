import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { DisplayTodaysDate } from "./Helpers";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import HomeIcon from "@mui/icons-material/Home";
import Badge from "@mui/material/Badge";
import { useFavorites } from "../contexts/FavoritesContext";

const Header = ({ user }) => {
  const { favorites } = useFavorites();
  const [anchorEl, setAnchorEl] = useState(null);
  const { pathname } = useLocation(); // determine where to send user
  const openMenu = Boolean(anchorEl);

  let navigate = useNavigate();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("current-user"); // remove user from local storage
    sessionStorage.removeItem("Auth Token");
    navigate("/login");
  };

  useEffect(() => {}, []);

  return (
    <>
      <div>
        <div className="left">
          <Link
            to={{
              pathname: "/home",
            }}
          >
            <div className="logosmall"></div>
          </Link>
          <div className="date">{DisplayTodaysDate()}</div>
        </div>
      </div>

      <Box
        sx={{
          float: "right",
          display: "flex",
          alignItem: "center",
          flexWrap: "wrap",
        }}
      >
        <Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>
          {user?.data?.username ? user.data.username : ""}
          {user?.data?.username && user?.data?.title ? ", " : ""}
          {user?.data?.title ? user.data.title : ""}
        </Box>

        {(user?.data?.role == "admin" || user?.data?.role == "tagging") && (
          <Link
            to={{
              pathname: "/cart",
            }}
          >
            <Box sx={{ mr: 1 }}>
              <Badge
                badgeContent={favorites?.length}
                sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    backgroundColor: "green",
                  },
                }}
              >
                <ShoppingCartIcon fontSize="medium" />
              </Badge>
            </Box>
          </Link>
        )}

        <Box>
          <MenuIcon fontSize="medium" onClick={handleMenuClick} />
        </Box>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {user?.data?.role === "admin" && (
            <MenuItem
              onClick={() => {
                navigate("/home");
              }}
              disabled={pathname == "/home"}
            >
              <ListItemIcon>
                <HomeIcon fontSize="small" />
              </ListItemIcon>
              Home
            </MenuItem>
          )}

          {user?.data?.role === "admin" && (
            <MenuItem
              onClick={() => {
                navigate("/admin");
              }}
              disabled={pathname == "/admin"}
            >
              <ListItemIcon>
                <AdminPanelSettingsIcon fontSize="small" />
              </ListItemIcon>
              Admin
            </MenuItem>
          )}

          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default Header;
