import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Input,
  InputLabel,
  Button,
} from "@mui/material";
import useUpdatePlant from "../../../hooks/useUpdatePlant";

export default function EditPlant({ id, name, onUpdate }) {
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [plantName, setPlantName] = useState(name);
  const updatePlant = useUpdatePlant();

  const handleOpenEditDialog = () => {
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
  };

  const onPlantIdChange = (e) => setPlantName(e.target.value);

  const onSubmit = async () => {
    if (!plantName) {
      return alert("Invalid plant id");
    }

    try {
      await updatePlant(id, plantName);
      onUpdate(id, plantName);
      setPlantName("");
      handleCloseEditDialog();
    } catch (e) {
      console.log(">>> error creating plant", e);
    }
  };

  return (
    <>
      <img
        src="/icons/edit.svg"
        alt="Edit plant row"
        width="28px"
        height="28px"
        onClick={handleOpenEditDialog}
      />
      <Dialog
        open={isEditDialogOpen}
        maxWidth="xl"
        onClose={handleCloseEditDialog}
        sx={{fontFamily: 'Lato'}}
      >
        <DialogTitle sx={{fontFamily: 'Lato'}}>Edit Plant Name</DialogTitle>
        <DialogContent style={{ width: "450px" }} sx={{fontFamily: 'Lato'}}>
          <span className="">Existing Plant ID</span>
          <br />
          {id} - {name}
          <FormControl style={{ width: "100%", marginTop: 10 }}>
            <InputLabel htmlFor="plant-name">Updated Plant Name</InputLabel>
            <Input
              id="plant-name"
              placeholder="e.g. BERBERIS thun. Rose Glow"
              defaultValue={plantName}
              value={plantName}
              onChange={onPlantIdChange}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="primary" onClick={handleCloseEditDialog} sx={{fontFamily: 'Lato'}}>
            Cancel
          </Button>
          <button className="primary-btn" onClick={onSubmit} sx={{fontFamily: 'Lato'}}>Update</button>
        </DialogActions>
      </Dialog>
    </>
  );
}
