import { useState } from "react";
import {
  IconButton,
  TextField,
  FormControl,
  Box,
  Typography,
  Select,
  MenuItem,
  Autocomplete,
  Stack,
  Checkbox, 
  FormControlLabel
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useGetData } from "../../hooks";

export default function ImagesFilters({
  resetFilters,
  filters,
  setFilters,
  search = true,
  defaultFiltersOpen,
}) {
  const [filtersVisible, setFiltersVisible] = useState(defaultFiltersOpen);
  const { data: plants } = useGetData("plants");
  const { data: yards } = useGetData("yards");

  const toggleFiltersVisible = () => setFiltersVisible(!filtersVisible);

  const onAutocompleteChange = (_, value) => {
    setFilters({ plantId: value.id, hideBeforeDecember2023: true });
  };

  const onDateChange = (key) => (date) =>
    setFilters((oldFilters) => ({ ...oldFilters, [key]: date }));

  const onYardChange = (e) =>
    setFilters((oldFilters) => ({ ...oldFilters, yard: e.target.value }));

  const onSortChange = (e) =>
    setFilters((oldFilters) => ({ ...oldFilters, sort: e.target.value }));

  const onHideBeforeDecember2023Change = (e) =>
    setFilters((oldFilters) => ({
      ...oldFilters,
      hideBeforeDecember2023: e.target.checked,
    }));

  return (
    <>
      {search && (
        <div style={{ display: "flex" }}>
          <IconButton sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <Autocomplete
            noOptionsText={"No more options"}
            variant="standard"
            id="search2"
            options={plants}
            style={{ marginTop: "-14px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search for a plant in our photo library"
                variant="standard"
                sx={{
                  "& .MuiInputBase-input": { fontFamily: 'Lato' },
                  "& .MuiInputLabel-root": { fontFamily: 'Lato' },
                }}
              />
            )}
            getOptionLabel={(option) => `${option.id} ${" - "} ${option.name}`}
            fullWidth
            onChange={onAutocompleteChange}
            onInputChange={(event, newInputValue, reason) => {
              if (reason === "clear") {
                resetFilters();
              }
            }}
          />
          <IconButton onClick={toggleFiltersVisible} sx={{ p: "10px" }}>
            <FilterAltIcon />
          </IconButton>
        </div>
      )}
      <div className={`${filtersVisible ? "show" : "hide"} bottom-section`}>
        {!defaultFiltersOpen && (
          <IconButton
            fontSize="small"
            style={{ float: "right" }}
            onClick={toggleFiltersVisible}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Box display="flex" justifyContent="space-between">
          <FormControl
            variant="outlined"
            sx={{
              m: 2,
              mt: defaultFiltersOpen ? 2 : 8,
              minWidth: 150,
              height: 30,
              color: "black",
            }}
          >
            <Typography component="div">
              <Box sx={{ fontStyle: "italic", m: 0.5 }}>Sort By</Box>
            </Typography>
            <Select
              fullWidth
              id="filter-sortby-select"
              displayEmpty
              value={filters.sort}
              onChange={onSortChange}
              sx={{fontFamily: 'Lato'}}
            >
              <MenuItem sx={{fontFamily: 'Lato'}} value="newest">Newest</MenuItem>
              <MenuItem sx={{fontFamily: 'Lato'}} value="latest">Latest</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            sx={{
              mr: 2,
              mt: defaultFiltersOpen ? 2 : 8,
              minWidth: 150,
              height: 30,
            }}
          >
            <Typography component="div">
              <Box sx={{ fontStyle: "italic", m: 0.5 }}>Yard</Box>
            </Typography>
            <Select
              fullWidth
              displayEmpty
              id="filter-yard-select"
              value={filters.yard}
              onChange={onYardChange}
              sx={{fontFamily: 'Lato'}}
            >
              <MenuItem value="" sx={{fontFamily: 'Lato'}}>
                <em>All Yards</em>
              </MenuItem>
              {yards?.map((yard, index) => (
                <MenuItem key={`yard-${yard}-${index}`} value={yard.name} sx={{fontFamily: 'Lato'}}>
                  {yard.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            sx={{
              mb: 2,
              mt: defaultFiltersOpen ? 2 : 8,
              mr: defaultFiltersOpen ? 2 : 0,
              minWidth: 350,
            }}
          >
            <Typography component="div">
              <Box sx={{ fontStyle: "italic", m: 0.5, fontFamily: 'Lato' }}>Date Range</Box>
            </Typography>
            <Stack direction="row" spacing={2}>
              <DatePicker
                label="Start Date"
                value={filters.startDate}
                maxDate={filters.endDate}
                onChange={onDateChange("startDate")}
                renderInput={(params) => <TextField {...params} sx={{fontFamily: 'Lato'}} />}
              />

              <DatePicker
                label="End Date"
                value={filters.endDate}
                minDate={filters.startDate}
                onChange={onDateChange("endDate")}
                renderInput={(params) => <TextField {...params} sx={{fontFamily: 'Lato'}} />}
              />
            </Stack>
          </FormControl>
          </Box>
          <Box>
          <FormControl
            variant="outlined"
            style={{ display: "flex", alignItems: "start", padding: "20px", marginTop: "0px" }}
            sx={{
              mb: 2,
              mt: defaultFiltersOpen ? 2 : 8,
              mr: defaultFiltersOpen ? 2 : 0,
              minWidth: 350,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.hideBeforeDecember2023}
                  onChange={onHideBeforeDecember2023Change}
                  name="hideBeforeDecember2023"
                  color="primary"
                />
              }
              label="Hide all images before December 31, 2023"
              sx={{ fontFamily: 'Lato' }}
            />
          </FormControl>
        </Box>
      </div>
    </>
  );
}
