import axios from 'axios';
import { baseUrl } from '../_constants';
import { getAuthToken } from '../utils';

export default function useArchiveImage() {
    const deleteImage = async (id) => {
      try {
        const api = `${baseUrl}/plant-photos/${id}`;
        const authToken = await getAuthToken()
        if (!authToken) {
          console.error('ImageGrid Not authenticated to archive')
          throw new Error('Unauthenticated')
        }
        return await axios.patch(
          api,
          {
            archived: true
          },
          {
            headers: {"Authorization" : `Bearer ${authToken}`}
          }
        )
      } catch (error) {
        console.log(error);
      }
    }

    return deleteImage;
}
