import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../_constants";
import { getAuthToken } from "../utils";

export default function useGetData(path, enabled = true) {
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getData = async (queryParams = "") => {
    try {
      const authToken = await getAuthToken();

      if (!authToken) {
        console.error(`ImageGrid Not authenticated to getData: ${path}`);
        return setError("Unauthenticated");
      }

      const api = `${baseUrl}/${path}/${queryParams}`;
      const response = await axios.get(api, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      setData(sanitize(response.data.data, path));
    } catch (e) {
      console.log(">>> ERROR", e);
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (enabled) {
      getData();
    }
  }, []);

  return {
    data,
    error,
    isLoading,
    refetch: getData,
  };
}

const sanitize = (data, path) => {
  let sanitizedData = [];

  if (path.includes("plant-photos")) {
    data.forEach((row) => {
      const [id, details] = Object.entries(row)[0];
      sanitizedData.push({
        id,
        ...details,
      });
    });
  } else {
    sanitizedData = [...data];
  }

  return sanitizedData;
};
