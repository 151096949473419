import React, { useState } from "react";
import { Box, Modal as MUIModal, Typography } from "@mui/material";
import { useFetch } from "../../hooks/useFetch";
import { baseUrl } from "../../_constants";
import Form from "./Form";

export default function Modal({ onCreate }) {
  const { data: yards } = useFetch(`${baseUrl}/yards`);
  const { data: plants } = useFetch(`${baseUrl}/plants`);
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);

  const onClose = () => setIsOpen(false);

  const onCreateSuccess = () => {
    onClose();
    onCreate();
  };

  return (
    <>
      <button className="primary-btn upload-plant-btn" onClick={onOpen}>Create Plant Photo</button>
      <MUIModal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "white",
            maxWidth: 960,
            margin: "100px auto",
            borderRadius: 3,
            padding: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2" ml={3} sx={{ fontFamily: 'Lato' }}>
            Create Plant Photo
          </Typography>
          <Form yards={yards} plants={plants} onCreate={onCreateSuccess} />
        </Box>
      </MUIModal>
    </>
  );
}
