import axios from 'axios';
import { baseUrl } from '../_constants';
import { getAuthToken } from '../utils';

export default function useAddToCart() {
  
  const addToCart = async (id) => {
      const authToken = await getAuthToken();
      if (!authToken) {
        console.error('ImageGrid Not authenticated to addToCart')
        throw new Error('Unauthenticated')
      }

      const api = `${baseUrl}/user-favorites/${id}`;
      return axios.post(api,'',{ headers: {"Authorization" : `Bearer ${authToken}`}})
    }

    return addToCart;
}